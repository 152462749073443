import React from "react";
import SectionTitleS2 from "../SectionTitleS2";
import pImg1 from "../../images/portfolio/sweet-moments-1.jpg";
import pImg2 from "../../images/portfolio/sweet-moments-2.jpg";
import pImg3 from "../../images/portfolio/sweet-moments-3.jpg";
import pImg4 from "../../images/portfolio/sweet-moments-4.jpg";
import pImg5 from "../../images/portfolio/sweet-moments-5.jpg";
import pImg6 from "../../images/portfolio/sweet-moments-6.jpg";

/* Extend Sweet Moments section from 6 to 12 photos
All images must be 422 or 422 x 442
the first and last images are 895 x 438 and 895 x 440

*/ 
// import pImg7 from "../../images/portfolio/sweet-moments-7.jpg";
// import pImg8 from "../../images/portfolio/sweet-moments-8.jpg";
import pImg9 from "../../images/portfolio/sweet-moments-9.jpg";
import pImg10 from "../../images/portfolio/sweet-moments-10.jpg";
import pImg11 from "../../images/portfolio/sweet-moments-11.jpg";
import pImg12 from "../../images/portfolio/sweet-moments-12.jpg";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css"; 

const Portfolios = [
  {
    Pimg: pImg1,
  },
  {
    Pimg: pImg2,
  },
  {
    Pimg: pImg3,
  },
  {
    Pimg: pImg4,
  },
  {
    Pimg: pImg5,
  },
  {
    Pimg: pImg6,
  },
  // {
  //   Pimg: pImg7,
  // },
  // {
  //   Pimg: pImg8,
  // },
  {
    Pimg: pImg9,
  },
  {
    Pimg: pImg10,
  },
  {
    Pimg: pImg11,
  },
  {
    Pimg: pImg12,
  },
];

const PortfolioSection = (props) => {
  return (
    <section
      className={`wpo-portfolio-section-s2 section-padding ${props.pSclass}`}
      id="gallery"
    >
      <div className="container-fluid">
        <SectionTitleS2 MainTitle={"Sweet Moments"} />
        <div className="sortable-gallery">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-grids gallery-container clearfix">
                {Portfolios.map((portfolio, pitem) => (
                  <div className="grid" key={pitem}>
                    <div className="img-holder">
                      <ReactFancyBox
                        thumbnail={portfolio.Pimg}
                        image={portfolio.Pimg}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioSection;

import React, { Component } from "react";
import CountDown from "reactjs-countdown";

class TimeCountDown extends Component {
  render() {
    return (
      <section className="wpo-wedding-date section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="clock-grids">
                <CountDown deadline="Dec 17, 2022" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TimeCountDown;

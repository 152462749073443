import React from "react";
import LocationMap from "../Modal";

const Hero7 = (props) => {
  return (
    <section className="static-hero-s3" id="hero7">
      <div className="hero-container">
        <div className="hero-inner">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="wpo-event-item">
                  <div className="wpo-event-text">
                    <h3>Andreea & Henry</h3>
                    <small>Saturday, Dec 17th, 2022</small>
                    {/* <small>Note : Schedule subject to change</small> */}
                    <p>3:00 PM | Guests Arrival and Greetings</p>
                    <p>3:30 PM | Wedding Ceremony</p>
                    <p>4:15 PM | Group Pictures & Social Hour</p>
                    <p>5:30 PM | Dinner and Reception</p>
                    <ul>
                      <li><strong>Fantasy Farm</strong></li>
                      <li>50 Pottery Rd, Toronto, ON M4K 2J2</li>
                    </ul>
                    <p>Invitation sent by email. Please RSVP</p>
                    <LocationMap/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero7;

import React from "react";
import { Link } from "react-router-dom";

/*
Contact Us Page 

uses images/cta/bg-3.jpg (in css and scss) for background image


*/ 



const CtaSection2 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <div className="wpo-cta-section-s3">
      <div className="conatiner-fluid">
        <div className="wpo-cta-item">
          <h2>See you all soon!</h2>
          <p>Here's how to contact us!</p>
          <Link onClick={ClickHandler} className="theme-btn-s2" to="/contact">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CtaSection2;

import React from 'react'
import SectionTitleS2 from '../SectionTitleS2'
import sImg1 from '../../images/portfolio/love-story-1.jpg'
import sImg2 from '../../images/portfolio/love-story-2.jpg'
import sImg3 from '../../images/portfolio/love-story-3.jpg'
import sImg4 from '../../images/portfolio/love-story-4.jpg'

import shape from '../../images/story/shape.png'

/* 
Henry and Andreea's Love Story
*/


const StorySection = (props) => {
    return(
        <section className="story-section section-padding" id="story">
            <div className="container">
                <SectionTitleS2 MainTitle={'Our Love Story'}/>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="story-timeline">
                            <div className="round-shape"></div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="story-text right-align-text">
                                        <h3>How we met</h3>
                                        <span className="date">October, 2012</span>
                                        <p>Andreea and Henry met at McGill University, in their first-year residence dormitory. After working on some calculus problems in the study room, they decided to be physics lab partners. Only afterwards, Andreea came to realize that Henry never took physics in high school ... (Both still aced the course though).</p>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder">
                                        <img src={sImg1} alt="" className="img img-responsive"/>
                                        <div className="story-shape-img">
                                            <img src={shape} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder right-align-text left-site">
                                        <img src={sImg2} alt="" className="img img-responsive"/>
                                        <div className="story-shape-img">
                                            <img src={shape} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <h3>First Date</h3>
                                        <span className="date">March 22, 2013</span>
                                        <p>Andreea and Henry went for pizza and a movie for their first date. This was after watching WALL-E together a few nights ago. They went to <a target="_blank" href="https://ilfocolaio.ca/en/">Il Focolaio</a>, one of the best pizza places in Montreal! Ironically, neither could remember what movie they saw in theatre. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12 text-holder right-heart">
                                    <span className="heart">
                                        <i className="fi flaticon-calendar"></i>
                                    </span>
                                    <div className="story-text right-align-text">
                                        <h3>Marriage Proposal</h3>
                                        <span className="date">August 19, 2020</span>
                                        <p>After 7 years of dating, moving in together, graduating, and adopting the loveliest cat ever 🐱, Henry decided to pop the question. Given the pandemic, Henry and Andreea took their annual vacation to see Banff, Jasper and the west coast. At arguably the best Fairmont in Canada (Lake Louise), Henry proposed to Andreea during a hike, overlooking the lake and mountains. </p>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder right-align-text">
                                        <img src={sImg3} alt="" className="img img-responsive"/>
                                        <div className="story-shape-img">
                                            <img src={shape} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder video-holder left-site">
                                        <img src={sImg4} alt="" className="img img-responsive"/>
                                        <div className="story-shape-img">
                                            <img src={shape} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-dove"></i>
                                    </span>
                                    <div className="story-text">
                                        <h3>Our Engagement</h3>
                                        <span className="date">December, 2021</span>
                                        <p>Andreea and Henry are enjoying a long engagement. They took their engagement trip to Oahu, Hawaii in December 2021. Eating poke, surfing, snorkeling and hiking the lush tropical terrain, Andreea and Henry discovered paradise on Earth.  We are so excited to celebrate our next chapter in our lives with you. See you in Toronto!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col offset-lg-6 col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-wedding-rings"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StorySection;
import React from 'react'

const BannerSection = (props) => {

    return(
        <section className="wpo-banner-section">
            <h4>Saturday December 17th, 2022 at Fantasy Farm, Toronto</h4>
        </section>
    )
}

export default BannerSection;
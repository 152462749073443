/*
Main Page for the website
for Henry and Andreea
HomePage3 template used

Need to bookmark
For reference:
Theme was purchased on Envato (go to gmail -> find email -> click link to sign into envato)
Click on Envatomarket, go to theme -> live preview : 
https://preview.themeforest.net/item/loveme-wedding-planner-react-template/full_screen_preview/35120036

*/
import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2";
import Hero3 from "../../components/hero3";
import TimeCountDown from "../../components/countdown";
import CoupleSection from "../../components/CoupleSection";
import StorySection from "../../components/StorySection";
/* Remove  */ 
// import VideoSection2 from "../../components/VideoSection2";
import PortfolioSection from '../../components/PortfolioSection';
/* Remove RSVP Section (RSVP section to be handled by Greenvelope or RSVPify) */  
// import RSVP from "../../components/RSVP";

/* Remove CTA2 section */
import CtaSection2 from "../../components/CtaSection2";

// import BlogSection2 from "../../components/BlogSection2";
import Scrollbar from "../../components/scrollbar";
/* Change Loveme logo to favicon 
import Logo from "../../images/logo.png";
*/
import Logo from "../../images/favicon.png";
import Footer from "../../components/footer";
// import PartnerSection from "../../components/PartnerSection";

/* Event Section will be re-structed as Q & A */ 
import EventSection from "../../components/EventSection";

/* Use Hero7 for Invitation section */ 
import Hero7 from "../../components/hero7";

/* Use Banner Section */ 
import BannerSection from "../../components/BannerSection";

const HomePage3 = () => {
  return (
    <Fragment>
      <Navbar2 Logo={Logo} topbarNone={"topbarNone"} />
      <Hero3 />
      <BannerSection/>
      <TimeCountDown />
      <CoupleSection />
      {/* <VideoSection2 /> */}
      <StorySection />
      <PortfolioSection/> {/* Put in Portfolio Section */}
      {/* <RSVP /> */}
      <Hero7 />
      <EventSection />
      {/* <PartnerSection tNone={"wpo-partners-section-s3"} /> */}
      {/* <BlogSection2 blogClass={"wpo-blog-section-s3"} /> */}
      <CtaSection2 />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default HomePage3;

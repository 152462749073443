import React from "react";
import SectionTitleS2 from "../SectionTitleS2";
import sImg1 from "../../images/event-shape-1.png";
import sImg2 from "../../images/event-shape-2.png";
import LocationMap from "../Modal";

const Events = [
  {
    Simg1: sImg1,
    Simg2: sImg2,
    title: "Invitation / RSVP",
    li1: "Please check your email for the formal invitation",
    li2: "Please RSVP through the email invite",
    li3: "Contact us if you have any technical difficulties",
    li4: "",
  },
  {
    Simg1: sImg1,
    Simg2: sImg2,
    title: "Accomidations",
    li1: "For out-of-town guests, ",
    li2: "We have secured a discounted rate at the ",
    li3: "Westin Harbour Castle Toronto",
    li4: "Please contact us to book",
  },
  {
    Simg1: sImg1,
    Simg2: sImg2,
    title: "Venue",
    li1: "Fantasy Farm Toronto",
    li2: "50 Pottery Rd, Toronto, ON M4K 2J2",
    li3: "(416) 425-9122",
    li4: <LocationMap /> ,
  },
  {
    Simg1: sImg1,
    Simg2: sImg2,
    title: "Gifts & Registry",
    li1: "Please no physical gifts (we may be moving in 2023!)",
    li2: "Sharing this special day with you is all we wish for",
    li3: "❤️",
    li4: "",
  },
  {
    Simg1: sImg1,
    Simg2: sImg2,
    title: "Dress Code",
    li1: "Semi Formal / Formal",
    li2: "There will be lots of photos and dancing!",
    li3: "Outside patio will be heated",
    li4: "",
  },
  {
    Simg1: sImg1,
    Simg2: sImg2,
    title: "Photos",
    li1: "A photographer will be onsite to take photos",
    li2: "We will share all photos with guests after the wedding",
    li3: "",
    li4: "",
  },
];

const EventSection = (props) => {
  return (
    <section
      className={`wpo-event-section section-padding ${props.eClass}`}
      id="events"
    >
      <div className="container">
        <SectionTitleS2 MainTitle={"Additional Information"} />
        <div className="wpo-event-wrap">
          <div className="row">
            {Events.map((event, eitem) => (
              <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                <div className="wpo-event-item">
                  <div className="wpo-event-text">
                    <h2>{event.title}</h2>
                    <ul>
                      <li>{event.li1}</li>
                      <li>{event.li2}</li>
                      <li>{event.li3}</li>
                      <li>
                        {/* <LocationMap /> */}
                        {event.li4}
                      </li>
                    </ul>
                  </div>
                  <div className="event-shape-1">
                    <img src={event.Simg1} alt="" />
                  </div>
                  <div className="event-shape-2">
                    <img src={event.Simg2} alt="" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventSection;
